// ------home page
import { Component, Vue } from "vue-property-decorator";
import ToFooter from "@/components/footer/footer.vue";
import Header from "@/components/header/header.vue";

@Component({
  components: {
      ToFooter,
      Header,
  }
})
export default class Home extends Vue {
    private toptype:number=1;

    // 左侧菜单跳转
    toPage(index:number) {
       let owner=this;
       owner.toptype=index;
       if(index===1){
           this.$router.push({
               path: "/home",
               query: {}
           });
       }else if(index===2){
           this.$router.push({
               path: "/companyprofile",
               query: {}
           });
       }else if(index===3){
           this.$router.push({
               path: "/product",
               query: {}
           });
       }else if(index===4){
           this.$router.push({
               path: "/news",
               query: {}
           });
       }else if(index===5){
           this.$router.push({
               path: "/mine",
               query: {}
           });
       }else if(index===6){
           this.$router.push({
               path: "/productdetail2",
               query: {}
           });
       }else if(index===7){
           this.$router.push({
               path: "/productdetail4",
               query: {}
           });
       }
    }
    gotodetail(){
        this.$router.push({
            path: `/productdetail6`,
            query: {
                // title:item.title
            }
        });
    }
    gotodetail7(){
        this.$router.push({
            path: `/productdetail7`,
            query: {
                // title:item.title
            }
        });
    }
    gotodetail2(){
        this.$router.push({
            path: `/productdetail2`,
            query: {
                // title:item.title
            }
        });
    }
    gotodetail4(){
        this.$router.push({
            path: `/productdetail4`,
            query: {
                // title:item.title
            }
        });
    }
    gotodetail1(){
        this.$router.push({
            path: `/productdetail1`,
            query: {
                // title:item.title
            }
        });
    }

  private created() {
      window.scrollTo(0, 0);
  }
}
